
/* eslint-disable */
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "0.73.6 (bd35881bddcc0d8bcb80c8ac3bc2d9f1)",
    buildSeed: 1654243289018,
    "wdosbox.shared.wasm": {
        "size": 1503846,
        "gzSize": 505037
    },
    "wdosbox.shared.js": {
        "size": 158672,
        "gzSize": 39869
    },
    "wdosbox.wasm": {
        "size": 1485705,
        "gzSize": 502002
    },
    "wdosbox.js": {
        "size": 125703,
        "gzSize": 32624
    },
    "wlibzip.wasm": {
        "size": 113025,
        "gzSize": 54583
    },
    "wlibzip.js": {
        "size": 77039,
        "gzSize": 20109
    }
,
};
